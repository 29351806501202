import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import IntroBlock from "../components/blocks/IntroBlock"

import ScrollspyNav from "react-scrollspy-nav"

const Glossary = ({ data }) => {
  const [termsState, setTermsState] = useState([])
  const [lettersArray, setLettersArray] = useState([])
  const [isSticky, setSticky] = useState(false)

  const lettersRef = useRef(null)
  const termGroupRefs = useRef([])
  termGroupRefs.current = []

  const glossaryInfo = data.glossaryInfo.edges
  const terms = data.terms.edges

  useEffect(() => {
    const cleanedTerms = terms.map(term => {
      const initial = term.node.term.charAt(0).toUpperCase()
      return {
        term: term.node.term,
        description: term.node.description,
        initial,
      }
    })

    const orderedTerms = cleanedTerms.sort((a, b) =>
      a.term > b.term ? 1 : b.term > a.term ? -1 : 0
    )

    let groupedTerms = orderedTerms.reduce((r, e) => {
      // get first letter of name of current element
      let group = e.term[0]
      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, children: [e] }
      // if there is push current element to children array for that letter
      else r[group].children.push(e)
      // return accumulator
      return r
    }, {})

    groupedTerms = Object.values(groupedTerms)

    if (termsState.length === 0) {
      setTermsState(groupedTerms)
    }
  }, [])

  const addToRefs = el => {
    if (el && !termGroupRefs.current.includes(el)) {
      termGroupRefs.current.push(el)
      if (!lettersArray.includes(el.id)) {
        setLettersArray(lettersArray => [...lettersArray, el.id])
      }
    }
  }

  const handleScroll = () => {
    if (lettersRef.current) {
      setSticky(lettersRef.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      console.log("unmounted")
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <div className="glossary-page">
      <Layout>
        <div className="main-col-one">
          <Seo title={glossaryInfo[0].node.intro.title} />

          <IntroBlock content={glossaryInfo[0].node.intro} />

          <div
            className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
            ref={lettersRef}
          >
            {lettersArray.length !== 0 && (
              <ScrollspyNav
                scrollTargetIds={lettersArray}
                offset={15}
                activeNavClass="is-active"
                scrollDuration="500"
              >
                <ul className="letters">
                  {termsState.map((group, key) => {
                    return (
                      <li key={key}>
                        <a
                          className="letter"
                          data-letter={group.group}
                          key={key}
                          href={"#" + group.group}
                        >
                          {group.group}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </ScrollspyNav>
            )}
          </div>

          <div className="all-terms">
            {termsState.map((group, key) => {
              return (
                <div
                  className="term-group"
                  id={group.group}
                  key={key}
                  ref={addToRefs}
                >
                  <div className="initial">{group.group}</div>
                  {group.children.map((term, i) => {
                    return (
                      <div className="term-wrap" key={i}>
                        <div className="term">{term.term}</div>
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{ __html: term.description }}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Glossary

export const pageQuery = graphql`
  {
    glossaryInfo: allStrapiGlossary {
      edges {
        node {
          intro {
            title
            subtitle
          }
        }
      }
    }
    terms: allStrapiGlossaryTerm {
      edges {
        node {
          term
          description
        }
      }
    }
  }
`
